<template>
  <Layout :is-nested="isNested">
    <template v-slot:input>
      <div class="flex items-center justify-between">
        <div
          class="flex items-center justify-between my-0.5 mb-2 text-left option"
        >
          <div class="text-xs text-gray-200 select-none option-label">
            {{ row.label }}

            <div
              v-if="isLockable"
              class="inline rounded-full px-0.5 cursor-pointer text-gray-600 hover:text-gray-400 hover:bg-gray-700"
              @click.prevent="locked = !locked"
            >
              <fa-icon v-if="locked" icon="lock" size="sm" class="fa-fw" />
              <fa-icon v-else icon="unlock" size="sm" class="fa-fw" />
            </div>
            <span v-if="row.extra" class="block text-xs italic text-gray-500">
              {{ row.extra }}
            </span>
          </div>
        </div>

        <div class="mt-1">
          <div class="flex flex-wrap justify-end">
            <template v-if="isLockable && locked">
              <PositionInputSingle
                :component-mode="componentMode"
                :label="combinedKey"
                :val="firstRow"
                :row="row"
                :disabled="isDisabled"
                @change="onGroupChange($event)"
              />
            </template>
            <template v-else>
              <template v-for="(val, k, index) in model">
                <PositionInputSingle
                  :component-mode="componentMode"
                  :label="k"
                  :val="val"
                  :row="row"
                  :disabled="isDisabled"
                  @change="onSingleChange(k, $event)"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'
import PositionComponentMode from '@src/enums/positionComponentMode'

const PositionInputSingle = () => import('./PositionInputSingle')

export default {
  name: 'PositionInput',

  components: {
    PositionInputSingle
  },

  mixins: [inputs],

  data() {
    return {
      model: this.row.values,
      model0: null
    }
  },

  computed: {
    componentMode() {
      switch (this.row.label) {
        case 'Position':
          return PositionComponentMode.POSITION
        case 'Rotation':
          return PositionComponentMode.ROTATION
        default:
          return PositionComponentMode.UNSET
      }
    },

    firstKey() {
      return Object.keys(this.model)[0]
    },

    firstRow() {
      return this.model[this.firstKey]
    },

    combinedKey() {
      return Object.keys(this.model).join('')
    }
  },

  watch: {
    row(value) {
      this.model = value.values
    }
  },

  mounted() {
    this.model0 = Object.assign(this.model, this.model0)
  },

  methods: {
    onGroupChange(value) {
      Object.keys(this.model).forEach((k) => {
        this.model[k] = Number(value)
      })

      this.$emit('change', this.model)
    },

    onSingleChange(k, value) {
      this.model[k] = Number(value)

      this.$emit('change', this.model)
    }
  }
}
</script>
